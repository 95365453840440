import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { Button, Col, Form, FormCheck, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import './phoneInput.scss'
import { updateUserStart } from '../../../../store/redux-slices/tenantUsers'
import { userSchema } from './schema'

import InputMask from 'react-input-mask';
import { FormikErrorMessage } from '../../../../components/ErrorMessage'
import { GENDER_INT, ROLLOVER_TYPE, ROLLOVER_TYPE_VALUE } from '../../../../utils/constant'

const selectOptionDefault = [{ label: "Selecione", value: 0 }];
const rolloverOptions = [...selectOptionDefault, ...Object.values(ROLLOVER_TYPE).map(value => ({ value, label: ROLLOVER_TYPE_VALUE[value] }))];

const EditUserModal = ({
  show, setShow, userData, isTenant = false
}) => {
  const { countries, tenantCountries } = useSelector((state) => state.fetch)
  const { isUpdateUserLoading } = useSelector((state) => state.tenantUsers)
  const dispatch = useDispatch()

  const country = isTenant ? tenantCountries?.rows : countries

  const showData = [
    { label: 'Email', value: 'email' },
    { label: 'First Name', value: 'firstName' },
    { label: 'Last Name', value: 'lastName' },
    { label: 'User Name', value: 'username', disabled: true },
    { label: 'Address', value: 'address' },
    //{ label: 'City', value: 'city' },
    { label: 'ZipCode', value: 'zipCode' },
    { label: 'CPF', value: 'cpf', mask: "999.999.999-99" }
  ]
  const successToggle = (resetForm) => {
    resetForm({ formValues: '' })
    setShow(false)
  }
  const userNewsLetter = () => {
    if (typeof userData?.newsLetter === 'object') return false
    return userData?.newsLetter
  }
  const userSms = () => {
    if (typeof userData?.sms === 'object') return false
    return userData?.sms
  }
  return (
    <>
      <Formik
        initialValues={{
          userId: userData?.userId,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          username: userData?.username,
          email: userData?.email,
          countryCode: userData?.countryCode,
          address: userData?.address,
          city: userData?.city,
          zipCode: userData?.zipCode,
          dateOfBirth: formatDateYMD(userData?.dateOfBirth),
          genderInt: !userData?.genderInt ? 0 : userData.genderInt,
          currencyCode: userData?.currencyCode,
          phoneCode: userData?.phoneCode || '',
          phone: userData?.phone || '',
          preferredLanguage: userData?.preferredLanguage || '',
          newsLetter: userNewsLetter() || false,
          sms: userSms() || false,
          cpf: userData?.cpf,
          rolloverType: userData?.rolloverType || 0,
          rolloverMultiplier: userData?.rolloverMultiplier || 0,
        }}
        validationSchema={userSchema}
        onSubmit={(formValues, { resetForm }) => {
          dispatch(updateUserStart({ data: formValues, isTenant, successToggle, resetForm }))
        }}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, handleSubmit, touched, setFieldValue, setFieldTouched, errors }) => {
          return <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop='static'
            keyboard={false}
            size='xl'
          >
            <Modal.Header closeButton>
              <h5>Atualizar {userData?.username}</h5>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {showData?.map(({ label, value, mask, disabled }) => (
                  <Col xs={6} key={value} className='mt-3'>
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                      name={value}
                      type='text'
                      as={InputMask}
                      disabled={disabled}
                      {...(mask ? { mask } : {})}
                      placeholder={`Enter ${label}`}
                      value={values[value]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <FormikErrorMessage
                      component='div'
                      name={value}
                      showIfEmpty
                      className='text-danger'
                    />
                  </Col>
                ))}
                <Col xs={6} className='mt-3'>
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    name={'phone'}
                    as={InputMask}
                    mask="+5599999999999"
                    type='text'
                    placeholder={`Informe o número de telefone`}
                    value={values?.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component='div'
                    name='phone'
                    className='error-message'
                    style={{ color: 'red' }}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={6} className='mt-3'>
                  <Form.Label>País</Form.Label>
                  <Form.Select
                    name='countryCode'
                    value={values?.countryCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {country?.map(
                      ({ code, name: countryName }) => (
                        <option value={code} key={code}>{countryName}</option>
                      ))}
                  </Form.Select>

                  <ErrorMessage
                    component='div'
                    name='countryCode'
                    className='text-danger'
                  />
                </Col>

                <Col xs={6} className='mt-3'>
                  <Form.Label>Data de nascimento</Form.Label>
                  <Form.Control
                    name='dateOfBirth'
                    type='date'
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='dateOfBirth'
                    className='text-danger'
                  />
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col>
                  <Form.Label className='me-2'>Gênero</Form.Label>

                  <FormCheck.Input
                    type='radio'
                    id='null'
                    name='genderInt'
                    label='null'
                    className='mx-2 cursor-pointer'
                    aria-label='null'
                    value={GENDER_INT.NOT_INFORMED}
                    checked={!values?.genderInt || values?.genderInt == GENDER_INT.NOT_INFORMED}
                    onChange={() => setFieldValue("genderInt", GENDER_INT.NOT_INFORMED)}
                    onBlur={handleBlur}
                  />
                  <FormCheck.Label className='me-3' htmlFor='null'>Não informado</FormCheck.Label>

                  <FormCheck.Input
                    type='radio'
                    id='Male'
                    name='genderInt'
                    label='Male'
                    className='mx-2 cursor-pointer'
                    aria-label='Male'
                    value={GENDER_INT.MALE}
                    checked={values?.genderInt === GENDER_INT.MALE}
                    onChange={() => setFieldValue("genderInt", GENDER_INT.MALE)}
                    onBlur={handleBlur}
                  />
                  <FormCheck.Label className='me-3' htmlFor='Male'>Masculino</FormCheck.Label>

                  <FormCheck.Input
                    type='radio'
                    id='Female'
                    name='genderInt'
                    label='Female'
                    className='mx-2 cursor-pointer'
                    aria-label='Female'
                    value={GENDER_INT.FEMALE}
                    checked={values?.genderInt === GENDER_INT.FEMALE}
                    onChange={() => setFieldValue("genderInt", GENDER_INT.FEMALE)}
                    onBlur={handleBlur}
                  />
                  <FormCheck.Label className='me-3' htmlFor='Female'>Feminino</FormCheck.Label>

                  <FormCheck.Input
                    type='radio'
                    id='Other'
                    name='genderInt'
                    label='Other'
                    className='mx-2 cursor-pointer'
                    aria-label='Other'
                    checked={values?.genderInt === GENDER_INT.OTHER}
                    value={GENDER_INT.OTHER}
                    onChange={() => setFieldValue("genderInt", GENDER_INT.OTHER)}
                    onBlur={handleBlur}
                  />
                  <FormCheck.Label className='me-3' htmlFor='Other'>Outros</FormCheck.Label>

                  <ErrorMessage
                    component='div'
                    name='genderInt'
                    className='text-danger'
                  />
                </Col>

                <Col className='d-flex'>
                  <Col>
                    <FormCheck.Input
                      type='checkbox'
                      name='newsLetter'
                      className='mx-2 cursor-pointer'
                      checked={values?.newsLetter}
                      onChange={(e) => setFieldValue('newsLetter', e.target.checked)}
                      onBlur={handleBlur}
                    />
                    <FormCheck.Label className='me-3' htmlFor='NewsLetter'>NewsLetter</FormCheck.Label>
                  </Col>
                  <Col>
                    <FormCheck.Input
                      type='checkbox'
                      name='gender'
                      className='mx-2 cursor-pointer'
                      checked={values?.sms}
                      onChange={(e) => setFieldValue('sms', e.target.checked)}
                      onBlur={handleBlur}
                    />
                    <FormCheck.Label className='me-3' htmlFor='SMS'>SMS</FormCheck.Label>
                  </Col>
                </Col>

              </Row>

              <hr className='mt-3' />

              <Row className='mt-3'>
                <Col xs={6} className='mt-3'>
                  <Form.Label>Rollover</Form.Label>
                  <Form.Select
                    name='rolloverType'
                    value={values?.rolloverType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {rolloverOptions?.map(
                      ({ label, value }) => (
                        <option value={value} key={value}>{label}</option>
                      ))}
                  </Form.Select>

                  <ErrorMessage
                    component='div'
                    name='rolloverType'
                    className='text-danger'
                  />
                </Col>
                <Col xs={6} className='mt-3'>
                  <Form.Label>Multiplicador do Rollover</Form.Label>
                  <Form.Control
                    type='number'
                    min={0}
                    step={0.5}
                    name='rolloverMultiplier'
                    value={values?.rolloverMultiplier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='rolloverMultiplier'
                    className='text-danger'
                  />
                </Col>
              </Row>

            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                  disabled={isUpdateUserLoading}
                >
                  {isUpdateUserLoading ? 'Loading...' : 'Submit'}
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        }}

      </Formik>
    </>
  )
}

export default EditUserModal

export const defaultCurrency = {
  name: 'Brazilian Dollar',
  code: 'BRL',
  exchangeRate: 1,
  symbol: 'R$'
}

export const REPORT_TYPE = {
  GAME: 'game',
  PROVIDER: 'provider',
  USER: 'user',
}

export const MONTHS = {
  january: "Janeiro",
  february: "Fevereiro",
  march: "Março",
  april: "Abril",
  may: "Maio",
  june: "Junho",
  july: "Julho",
  august: "Agosto",
  september: "Setembro",
  october: "Outubro",
  november: "Novembro",
  december: "Dezembro",
}

export const ROLE = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  USER: 'User',
  AFFILIATE: 'Affiliate',
  MERCHANT: 'Merchant',
  MANAGER: 'Manager',
}

/* ==========================================================================
 Deposit Minimum and Maximum  values
========================================================================== */
export const DEPOSIT_BLR_VALUES = {
  minimumDeposit: 1,
  maximumDeposit: 45000,
  processingTime: '5 minutes'
}

export const AFFILIATE_TYPE = {
  AFFILIATE: 1,
  STREAMER: 2,
  AGENCY: 3,
  OTHER: 4,
}

export const AFFILIATE_TYPE_LABEL = {
  AFFILIATE: "Afiliado",
  STREAMER: "Streamer",
  AGENCY: "Agência",
  OTHER: "Outro"
}

export const AFFILIATE_TYPE_LABEL_BY_VALUE = {
  1: "Afiliado",
  2: "Streamer",
  3: "Agência",
  4: "Outro"
}

export const AFFILIATE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  APPROVED: 'approved'
}

export const STATUS_LABEL = {
  PENDING: 'Pendente',
  SUCCESS: 'Sucesso',
  CANCELLED: 'Cancelado',
  FAILED: 'Falhou',
  ROLLBACK: 'Revertido',
  APPROVED: 'Aprovado',
  REJECTED: 'Rejeitado',
  REQUESTED: 'Requisitado',
  RE_REQUESTED: 'Re Requisitado',
}

export const STATUS_VALUE = {
  0: 'PENDING',
  1: 'SUCCESS',
  2: 'CANCELLED',
  3: 'FAILED',
  4: 'ROLLBACK',
  5: 'APPROVED',
  6: 'REJECTED',
  7: 'REQUESTED',
}


export const VISIBILYT_LABEL = {
  1: 'Visível',
  0: 'Não visível',
  true: 'Visível',
  false: 'Não visível',
  visible: 'Visível',
  "not-visible": 'Não visível',
}

export const VISIBILYT_VALUE = {
  1: 'visible',
  0: 'not-visible',
  true: 'visible',
  false: 'not-visible',
  visible: 'visible',
  "not-visible": 'not-visible',
}

export const ACTIVE_LABEL = {
  1: 'Ativo',
  0: 'Inativo',
  true: 'Ativo',
  false: 'Inativo',
  active: 'Ativo',
  inactive: 'Inativo',
}

export const ACTIVE_VALUE = {
  1: 'active',
  0: 'inactive',
  true: 'active',
  false: 'inactive',
  active: 'active',
  inactive: 'inactive',
}
export const YES_OR_NO_VALUE = {
  1: 'yes',
  0: 'no',
  true: 'yes',
  false: 'no',
  yes: 'yes',
  no: 'no',
  null: 'no',
  undefined: 'no',
}
export const YES_OR_NO_LABEL = {
  1: 'Sim',
  0: 'Não',
  true: 'Sim',
  false: 'Não',
  yes: 'Sim',
  no: 'Não',
  null: 'Não',
  undefined: 'Não',
}

export const CPA_TYPE = {
  DISABLE: 0,
  FIRST_DEPOSIT: 1,
  ALL_DEPOSITS: 2,
}

export const CPA_TYPE_VALUE = {
  DISABLE: "Desabilitado",
  FIRST_DEPOSIT: "Primeiro depósito",
  ALL_DEPOSITS: "Todos depósitos",
  0: "Desabilitado",
  1: "Primeiro depósito",
  2: "Todos depósitos",
}

export const ROLLOVER_TYPE = {
  FIRST_DEPOSIT: 1,
  ALL_DEPOSITS: 2,
}

export const ROLLOVER_TYPE_VALUE = {
  FIRST_DEPOSIT: "Primeiro depósito",
  ALL_DEPOSITS: "Todos depósitos",
  1: "Primeiro depósito",
  2: "Todos depósitos",
}

export const GLOBAL_SETTINGS_KEY = {
  SENDGRID: "SENDGRID",
  SITE_INFORMATION: "SITE_INFORMATION",
  ADMIN_INFORMATION: "ADMIN_INFORMATION",
  PAYMENT_TRANSACTIONS: "PAYMENT_TRANSACTIONS",
}

export const KYC_METHOD = {
  SYSTEM_KYC: 0,
  SUMSUB: 1,
}

export const KYC_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
}

export const AMOUNT_TYPE = {
  CASH: 0,
  NON_CASH: 1,
  CASH_NON_CASH: 2,
  COMMISSION: 3,
  CPA_COMMISSION: 4,
  SUB_AFFILIATE_COMMISSION: 5,
  LIVE_COMMISSION: 6,
}

export const AMOUNT_TYPE_LABEL = {
  CASH: "Dinheiro",
  NON_CASH: "Bônus",
  CASH_NON_CASH: "Dinheiro Bônus",
  COMMISSION: "Comissão",
  CPA_COMMISSION: "Comissão de CPA",
  SUB_AFFILIATE_COMMISSION: "Comissão de Sub-afiliados",
  LIVE_COMMISSION: "Comissão de lives",
  0: "Dinheiro",
  1: "Bônus",
  2: "Dinheiro Bônus",
  3: "Comissão",
  4: "Comissão de CPA",
  5: "Comissão de Sub-afiliados",
  6: "Comissão de lives",
}

export const GENDER_INT = {
  NOT_INFORMED: 0,
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
}

export const GENDER_LABEL = {
  NOT_INFORMED: "NOT_INFORMED",
  MALE: "MALE",
  FEMALE: "FEMALE",
  OTHER: "OTHER",
  0: "NOT_INFORMED",
  1: "MALE",
  2: "FEMALE",
  3: "OTHER",
}

export const BONUS_STATUS_INT = {
  PENDING: 0,
  ACTIVE: 1,
  FAILED: 2,
  EXPIRED: 3,
  CLAIMING: 4,
  CLAIMED: 5,
  PROCESS: 6,
  CANCELLED: 7,
  0: 'PENDING',
  1: 'ACTIVE',
  2: 'FAILED',
  3: 'EXPIRED',
  4: 'CLAIMING',
  5: 'CLAIMED',
  6: 'PROCESS',
  7: 'CANCELLED',
}
export const BONUS_STATUS_LABEL = {
  PENDING: "Pendente",
  ACTIVE: "Ativo",
  FAILED: "Falho",
  EXPIRED: "Expirado",
  CLAIMING: "Reividicar",
  CLAIMED: "Reividincado",
  PROCESS: "Processo",
  CANCELLED: "Cancelado",
  0: "Pendente",
  1: "Ativo",
  2: "Falho",
  3: "Expirado",
  4: "Reividicar",
  5: "Reividincado",
  6: "Processo",
  7: "Cancelado",
}
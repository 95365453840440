import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../Pagination'
import { statusType, tableHeaders, transactionType, walletType } from '../../pages/Super-Admin/TransactionsBanking/constants'
import { getDateTime } from '../../utils/dateFormatter'
import { findTypeStatus } from '../../pages/Super-Admin/SportsTransactions/constants'
import FormatNumber from '../../utils/formatNumber'
import FormatDate from '../../utils/formatDate'
import { STATUS_LABEL, STATUS_VALUE } from '../../utils/constant'

const TransactionsList = ({
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  transactions,
  loading = false
}) => {
  const type = transactionType

  const handleWalletType = ({ transactionType, amountType }) => {
    if (['addMoney', 'removeMoney', 'addMoneyInternal', 'removeMoneyInternal']?.includes(transactionType)) {
      return <>({walletType[amountType]})</>
    }
  }

  return (
    <>
      {/* Table with tenants info */}
      <div className='TableBg mt-4 pt-1'>

        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={`T-table_heading ${idx}`}
                  className={`${['Amount'].includes(h.label) && transactions?.count > 0 ? 'text-right' : ''}`}
                >
                  {h.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {transactions && !loading &&
              transactions?.rows?.map(
                ({
                  transactionBankingId,
                  transactionBankingUUID,
                  paymentTransactionId,
                  createdAt,
                  updatedAt,
                  actioneeEmail,
                  // paymentProvider,
                  amount,
                  currencyCode,
                  transactionType,
                  amountType,
                  actioneeType,
                  status,
                  transactionUser,
                  isFirstDeposit,
                  sourceBeforeBalance,
                  sourceAfterBalance,
                  targetBeforeBalance,
                  targetAfterBalance,
                  beforeBalance
                }) => {
                  return (
                    <tr key={`transaction-list ${transactionBankingId}`}>
                      <td>{transactionUser?.username}</td>
                      <td>{transactionBankingUUID || 'N/A'}</td>
                      <td>{actioneeType || 'N/A'}</td>
                      {/* <td>{paymentProvider || 'NA'}</td> */}
                      <td className={['withdraw', 'removeMoney'].includes(transactionType) ? 'text-danger text-right' : 'text-success text-right'}>
                        {['withdraw'].includes(transactionType) ? '- ' : ''}{FormatNumber.currency(amount || 0)}
                      </td>
                      <td className='text-left'>{type?.find((type) => type.value === transactionType)?.label} {handleWalletType({ transactionType, amountType })} </td>
                      <td><span className='w-100' data-badge={String(STATUS_VALUE[status]).toLowerCase()}>{STATUS_LABEL[STATUS_VALUE[status]]}</span></td>
                      <td className='text-right'>
                        {FormatNumber.currency(sourceBeforeBalance ?? targetBeforeBalance)}
                      </td>
                      <td className='text-right'>
                        {FormatNumber.currency(sourceAfterBalance ?? targetAfterBalance)}
                      </td>
                      <td>{isFirstDeposit ? "Sim" : "Não"}</td>
                      <td>{actioneeEmail || 'N/A'}</td>
                      {/* <td>{statusType?.find((type) => type.value === status)?.label}</td> */}
                      <td>{FormatDate.default(createdAt)}</td>
                      <td>{FormatDate.default(updatedAt)}</td>
                    </tr>
                  )
                })}

            {transactions?.count === 0 && !loading && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>Total de registros ({transactions?.count || 0})</div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {transactions?.count > 0 && !loading && (
              <PaginationComponent
                className="m-0"
                page={transactions?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionsList

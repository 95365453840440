import { BONUS_STATUS_INT, BONUS_STATUS_LABEL } from "../../../utils/constant"
import { bonusType } from "../Bonus/components/CreateBonus/constants"
import _ from "lodash";

export const reasonOptions = [
  'Image Not Visible Properly',
  'Not A Valid Document',
  'Document Validation Expired',
  'Add Custom Reason'
]

export const tableHeaders = [
  { label: 'Id', value: 'userBonusId' },
  { label: 'Nome', value: 'promotionTitle' },
  { label: 'Tipo de bônus', value: 'bonusType' },
  { label: 'Valor para apostar', value: 'amountToWager' },
  { label: 'Valor apostado', value: 'wageredAmount' },
  { label: 'Status', value: 'isActive' },
  { label: 'Criado em:', value: 'createdAt' },
  { label: 'Ativado em:', value: 'activatedAt' },
  { label: 'Reivindicado em:', value: 'claimedAt' },
  { label: 'Expirado em:', value: 'expiresAt' },
  { label: 'Atualizado em:', value: 'expiresAt' },
  { label: 'Action', value: 'action' }
]

export const bonusStatus = [
  { label: 'Todos', value: '' },
  ...Object
    .keys(_.pick(BONUS_STATUS_INT, [0, 1, 2, 3, 4, 5, 6]))
    .map(value => ({ value, label: BONUS_STATUS_LABEL[value] }))
]

export const bonusTypes = [
  { label: 'Todos', value: '' },
  ...bonusType,
]

export const affiliateList = [
  { label: 'Parent Affiliate ID', value: 'parentIdAffiliate' },
  { label: 'Parent Username', value: 'parentUsername' },
  { label: 'Affiliate ID', value: 'affiliateId' },
  { label: 'User Name', value: 'username' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Email', value: 'email' },
  { label: 'Country', value: 'country' },
  { label: 'Currency', value: 'currency' },
  { label: 'Mobile', value: 'mobile' }
]

export const userDisableReasons = [
  'Bonus abuser',
  'Duplicate account',
  'Fraudulent',
  'Risk country',
  'Rude behaviour',
  'Bannec country - connect with VPN or Tor Browser',
  'KYC declined',
  'Suspicios - constant deposits and withdrawals (money laundering)',
  'Contacts support for bigger amount of deposit than allowed',
  'Resonsible Gambling',
  'pending',
  'frozen',
  'deactivated',
  'permbanned',
  'tempbannedblocked',
  'gambling_issues',
  'self_excluded',
  'dpo_erasure_requested',
  'kyc_blocked_suspended',
  'kyc_blocked_duplicate',
  'kyc_blocked_bonus_abuse',
  'kyc_blocked_chargeback',
  'kyc_fraud',
  'failed_kyc',
  'aml_failed_sow',
  'aml_management_ban',
  'aml_on_hold',
  'aml_under_review',
  'rg_underaged',
  'rg_under_review',
  'enforced_self_ex',
  'location_ban',
  'cs_management_ban',
  'validated',
  'Other'
]
